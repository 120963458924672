<template>
    <div class="box" style="padding-top: 80px; box-sizing: border-box;">
        <Navigation nameColor="rgba(51, 51, 51, 1)" />
        <div class="crumbs">
            <div class="crumbs_ct flex">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/product' }">产品合作</el-breadcrumb-item>
                    <el-breadcrumb-item>技术开发</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <img :src="image" class="advertising" />
        <div class="scope_box">
            <credible_title title="服务范围" />
            <ul class="scope_list flex">
                <li v-for="(item, index) in scopeList" :key="index">
                    <i class="scope_index" :style="{ backgroundImage: 'url(' + item.iconIndex + ')' }"></i>
                    <i class="scope_icon" :style="{ backgroundImage: 'url(' + item.bigIcon + ')' }"></i>
                    <p class="show_1 flex">
                        <i class="icon" :style="{ backgroundImage: 'url(' + item.smallIcon + ')' }"></i>
                        <span>{{ item.title }}</span>
                    </p>
                    <div class="show_2 flex">
                        <i class="icon" :style="{ backgroundImage: 'url(' + item.bigIcon + ')' }"></i>
                        <div>
                            <p>{{ item.title }}</p>
                            <span>{{ item.content }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="cause_box">
            <div class="cause_ct_box flex">
                <div class="cause_title">
                    <h2>为什么选择我们？</h2>
                    <p>我们具有自身行业分析策划，前沿设觉设计、研发能力；具有完备的项目管理，完善的售后服务体系。</p>
                    <p>我们始终致力于为企业提供信息化服务，以先进完善的建站体系及不断开拓创新的精神理念，帮助企业客户真正实现电子商务应用，提高企业的竞争能力。</p>
                </div>
                <div class="cause_bg"></div>
            </div>
        </div>
        <div class="case_box">
            <credible_title title="客户案例" />
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div v-for="(item, index) in swiperList" :key="index" class="swiper-slide flex">
                        <div class="swiper_img">
                            <img :src="item.image" alt="">
                        </div>
                        <div class="case_ct_box flex">
                            <p class="case_ct_title">{{ item.title }}</p>
                            <p class="case_ct_content" v-html="item.content"></p>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <div class="swiper-button-next" v-if="swiperList.length > 1">
                    <div class="next"></div>
                </div>
                <div class="swiper-button-prev" v-if="swiperList.length > 1">
                    <div class="prev"></div>
                </div>
            </div>
        </div>
        <div class="footer_box" id="footer">
            <div class="footer_form_box flex">
                <div class="footer_title">
                    <h2>为您免费定制</h2>
                    <h2>专属的《网站策划方案与报价》</h2>
                    <p>
                        <img src="../assets/technical/mobile.png" alt="">
                        <span>{{ webUrl.cooperate_tel }}</span>
                    </p>
                </div>
                <div class="footer_form">
                    <h4>把您的需求告诉我们，让我们优秀的团队立即为您服务！</h4>
                    <el-checkbox-group v-model="form.style">
                        <el-checkbox :label="1">品牌网站</el-checkbox>
                        <el-checkbox :label="2">小程序</el-checkbox>
                        <el-checkbox :label="3">APP</el-checkbox>
                        <el-checkbox :label="4">商城网站</el-checkbox>
                        <el-checkbox :label="5">系统开发</el-checkbox>
                    </el-checkbox-group>
                    <div class="form flex">
                        <div class="form_input">
                            <p>公司名称</p>
                            <el-input v-model="form.company" placeholder="输入公司名称"></el-input>
                        </div>
                        <div class="form_input">
                            <p>联系人姓名</p>
                            <el-input v-model="form.contact" placeholder="输入您的姓名"></el-input>
                        </div>
                        <div class="form_input">
                            <p>联系人电话</p>
                            <el-input v-model="form.tel" placeholder="输入您的联系电话"></el-input>
                        </div>
                        <div class="form_input">
                            <p>联系人微信</p>
                            <el-input v-model="form.wechat" placeholder="输入您的微信"></el-input>
                        </div>
                    </div>
                    <button class="form_up" @click="postTech">提交获取取方案报价</button>
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import credible_title from '../components/credible_title.vue';
import Swiper from "swiper";
import 'swiper/css/swiper.min.css'
export default {
    name: '',
    components: {
        credible_title
    },
    created() {
        this.getBanner()
    },
    mounted() {
        this.getSwiper()

    },
    data() {
        return {
            input: '',
            image: '',
            scopeList: [
                {
                    iconIndex: require('../assets/technical/1.png'),
                    smallIcon: require('../assets/technical/small1.png'),
                    bigIcon: require('../assets/technical/big1.png'),
                    title: '品牌网站',
                    content: '企业公司网站、品牌网站、外贸网站、集团网站、营销型网站、响应式网站',
                },
                {
                    iconIndex: require('../assets/technical/2.png'),
                    smallIcon: require('../assets/technical/small2.png'),
                    bigIcon: require('../assets/technical/big2.png'),
                    title: '系统开发',
                    content: '后台系统、管理系统、数据可视化',
                },
                {
                    iconIndex: require('../assets/technical/3.png'),
                    smallIcon: require('../assets/technical/small3.png'),
                    bigIcon: require('../assets/technical/big3.png'),
                    title: '移动端',
                    content: '门店小程序、商城小程序、平台小程序、微官网、APP开发、公众号、H5',
                },
                {
                    iconIndex: require('../assets/technical/4.png'),
                    smallIcon: require('../assets/technical/small4.png'),
                    bigIcon: require('../assets/technical/big4.png'),
                    title: '商城网站',
                    content: '商城网站',
                },
            ],
            swiper: null,
            swiperList: [],
            webUrl: {},
            form: {
                style: [], //品牌网站
                company: '', //公司名称
                contact: '', // 姓名
                tel: '', // 电话
                wechat: '', //微信
            }

        }
    },
    methods: {
        getBanner() {
            this.curlGet('/api/advert/detail', {
                mark: 'skill_banner_01'
            }).then(res => {
                if (res.data.code) {
                    this.image = res.data.data.image
                }
                this.getwebsite()

            })
            this.curlGet('/api/article/list', {
                cate_id: '7'
            }).then(res => {
                console.log(res);
                if (res.data.code) {
                    this.swiperList = res.data.data
                    this.loading = false
                }
            })
        },
        getwebsite() {
            this.curlGet('/api/index/website').then(res => {
                if (res.data.code) {
                    this.webUrl = res.data.data
                }
            })
        },
        getSwiper() {
            this.swiper = new Swiper(".swiper-container", {
                loop: true, // 循环模式选项
                autoplay: { //自动开始
                    autoplay: true,
                    delay: 3000, //时间间隔
                    disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                },
                paginationClickable: true,
                slidesPerView: 1, // 一组三个
                spaceBetween: 30, // 间隔
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
                observer: true,
                observeParents: true,
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true, // 分页器可以点击
                },
            })
        },
        postTech() {
            this.curlPost('/api/tecdev/save', this.form).then(res => {
                console.log(res);
                if (res.data.code) {
                    this.$message({
                        message: '提交成功,等待客服回馈',
                        type: 'success'
                    })
                    this.form = {
                        style: [], //品牌网站
                        company: '', //公司名称
                        contact: '', // 姓名
                        tel: '', // 电话
                        wechat: '', //微信
                    }

                } else {
                    return this.$message({
                        message: res.data.msg,
                        type: 'error',
                        offset: 100,
                    });
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import './../scss/technical';
</style>